import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../lib/ui/dialog";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "../lib/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../lib/ui/form";
import { Input } from "../lib/ui/input";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../providers/SessionProvider";
import { post } from "../lib/fetch";

interface CreateAccountModalProps {
  openModal: boolean;
  onOpenChange: () => void;
  initialScreen?: string;
}

const createAccountFormSchema = z.object({
  name: z.string().min(3, "Name is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters"),
});

const CreateAccountContent = ({ setFlowState, openModal, onOpenChange }) => {
  const form = useForm<z.infer<typeof createAccountFormSchema>>({
    resolver: zodResolver(createAccountFormSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });
  const mutation = useMutation({
    mutationKey: ["mutation"],
    mutationFn: (values: z.infer<typeof createAccountFormSchema>) => {
      return post({ route: "/auth/create_account", values: values });
    },
    onError: (error: Error) => {
      if (error) {
        // Note that we only support email errors right now. More support for other stuff later
        form.setError("email", { message: error.message });
      }
    },
    onSuccess: () => {
      setFlowState("confirmation");
    },
  });

  // 2. Define a submit handler.
  const onSubmit = (values: z.infer<typeof createAccountFormSchema>) => {
    mutation.mutate(values);
  };

  return (
    <Dialog open={openModal} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white [&>*]:font-kufam px-16 py-12 rounded-3xl min-h-[500px] max-w-sm md:max-w-lg">
        <div className="flex flex-col items-center mt-4">
          <DialogTitle className="text-2xl">Create a new account</DialogTitle>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="text-center">
              <Button
                type="submit"
                size="lg"
                className="bg-[#818CF8] rounded-full h-[48px]"
                disabled={mutation.isPending || !form.formState.isValid}
              >
                create account
              </Button>
            </div>
          </form>
        </Form>
        <div className="flex flex-col items-center justify-center mt-[20px]">
          <div>
            <span className="text-sm">
              Already Have an account?{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setFlowState("signIn");
                }}
                className="text-[#A5B4FC]"
              >
                Sign In
              </a>
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const signInFormSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters"),
});

const SignInContent = ({ setFlowState, openModal, onOpenChange }) => {
  const api = useApi();
  const form = useForm<z.infer<typeof signInFormSchema>>({
    resolver: zodResolver(signInFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const mutation = useMutation({
    mutationKey: ["mutation"],
    mutationFn: (values: z.infer<typeof signInFormSchema>) => {
      return post({ route: "/auth/sign_in", values: values });
    },
    onError: (error: Error) => {
      if (error) {
        // Note that we only support email errors right now. More support for other stuff later
        form.setError("root.serverError", { type: "serverError", message: error.message });
      }
    },
    onSuccess: (user) => {
      api.updateAccount(user);
      setFlowState("signInComplete");
    },
  });

  // 2. Define a submit handler.
  const onSubmit = (values: z.infer<typeof signInFormSchema>) => {
    mutation.mutate(values);
  };

  return (
    <Dialog open={openModal} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white [&>*]:font-kufam px-16 py-12 rounded-3xl min-h-[500px] max-w-sm md:max-w-lg">
        <div className="flex flex-col items-center mt-4">
          <DialogTitle className="text-2xl">Sign In</DialogTitle>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.formState.errors.root?.serverError?.message && (
              <p className={"text-[0.8rem] font-medium text-destructive"}>
                {form.formState.errors.root.serverError.message}
              </p>
            )}
            <div className="text-center">
              <Button
                type="submit"
                size="lg"
                className="bg-[#818CF8] rounded-full h-[48px]"
                disabled={mutation.isPending || !form.formState.isValid}
              >
                sign in
              </Button>
            </div>
          </form>
        </Form>
        <div className="flex flex-col items-center justify-center mt-[20px]">
          {/* <div>
            <span className="text-sm">Forgot password?</span>
          </div> */}
          <div>
            <span className="text-sm">
              Don't have an account?{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setFlowState("createAccount");
                }}
                className="text-[#A5B4FC]"
              >
                Create an account
              </a>
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const AccountModal = ({
  openModal,
  onOpenChange,
  initialScreen,
}: CreateAccountModalProps) => {
  const [flowState, setFlowState] = useState("signIn");

  useEffect(() => {
    if (initialScreen) {
      setFlowState(initialScreen);
    }
  }, [initialScreen]);

  if (flowState === "confirmation") {
    return (
      <Dialog open={openModal} onOpenChange={onOpenChange}>
        <DialogContent className="bg-white [&>*]:font-kufam px-16 py-12 rounded-3xl min-h-[500px]">
          <div className="flex flex-col items-center justify-center">
            <DialogTitle className="text-2xl">
              Please check your inbox for a confirmation email.
            </DialogTitle>
            <DialogDescription className="mt-16">
              Click the link to confirm your email address.
            </DialogDescription>
          </div>
        </DialogContent>
      </Dialog>
    );
  } else if (flowState === "createAccount") {
    return (
      <CreateAccountContent
        openModal={openModal}
        onOpenChange={onOpenChange}
        setFlowState={setFlowState}
      ></CreateAccountContent>
    );
  } else if (flowState === "signIn") {
    return (
      <SignInContent
        openModal={openModal}
        onOpenChange={onOpenChange}
        setFlowState={setFlowState}
      ></SignInContent>
    );
  } else if (flowState === "signInCompleted") {
    <Dialog open={openModal} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white [&>*]:font-kufam px-16 py-12 rounded-3xl min-h-[500px]">
        <div className="flex flex-col items-center justify-center">
          <DialogTitle className="text-2xl">Sign in completed</DialogTitle>
          <DialogDescription className="mt-16">Close the tab and you're ready!</DialogDescription>
        </div>
      </DialogContent>
    </Dialog>;
  } else {
    return null;
  }
};
