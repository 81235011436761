import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useRootStoreContext } from "../providers/StoreProvider";
import { action } from "mobx";
import { Person } from "../lib/search";
import { SocialLink } from "./SearchPage";
import { BookOpen, Check, ExternalLink, MoveLeft, Plus } from "lucide-react";
import { AccountModal } from "./AccountModal";
import { useSession } from "../providers/SessionProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { post } from "../lib/fetch";
import { Button } from "../lib/ui/button";
import { NavBar } from "./NavBar";
import { useMediaQuery } from "react-responsive";
import { cn } from "../lib/utils";

export const MainSearch = observer(() => {
  const store = useRootStoreContext();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const session = useSession();
  const accountName = session?.account?.name;
  const firstLetter = accountName ? accountName[0] : "";
  const [initialScreen, setInitialScreen] = useState("signIn");
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (store.query != "") {
      store.search(store.query);
    }
  }, []);

  return (
    <div className="flex w-[100vw]">
      <NavBar page="main"></NavBar>
      <main
        className={cn(
          "relative px-4 mx-auto bg-custom-gradient w-[calc(100vw-210px)] min-h-[100vh] flex flex-col",
          isMobile && "w-[calc(100vw-56px)]"
        )}
      >
        <div className="flex justify-end items-center mt-4">
          {session.account ? (
            <div className="flex w-[150px] justify-end cursor-pointer items-center gap-1">
              <span className="hidden lg:block lg:whitespace-nowrap">Hi {accountName}</span>
              <div className="w-8 min-w-8 max-w-8 h-8 min-h-8 max-h-8 bg-[#A8B8EC] flex justify-center items-center rounded-full">
                <span>{firstLetter}</span>
              </div>
            </div>
          ) : (
            <div className="w-[150px] flex justify-end">
              <Button
                className="bg-[#A8B8EC] px-3 py-1 rounded-md"
                onClick={() => {
                  setOpenAccountModal(true);
                  setInitialScreen("signIn");
                }}
              >
                <span className="text-black">Sign In</span>
              </Button>
            </div>
          )}
        </div>
        <div className="flex flex-col h-full">
          <div className="sticky top-0 bg-white py-2 z-10 rounded-full px-8 mt-4">
            <form
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <input
                autoFocus
                type="text"
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                placeholder="Search by genre, agent, and/or agency"
                value={store.query}
                onChange={action((e) => {
                  store.query = e.target.value;
                  store.search(e.target.value);
                })}
              />
            </form>
          </div>
          <div className="flex flex-col h-full overflow-auto">
            <div className="flex mx-auto overflow-x-auto">
              <div className="flex flex-col justify-center items-center flex-1">
                <SearchResults
                  results={store.data?.people || []}
                  setOpenModal={(value) => {
                    setInitialScreen("createAccount");
                    setOpenAccountModal(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <AccountModal
          initialScreen={initialScreen}
          openModal={openAccountModal}
          onOpenChange={() => setOpenAccountModal(!openAccountModal)}
        />
      </main>
    </div>
  );
});

interface SearchResultsProps {
  results: Array<Person>;
  setOpenModal: (value: boolean) => void;
}

const SearchResults = observer(({ results, setOpenModal }: SearchResultsProps) => {
  const store = useRootStoreContext();
  const numPeople = store.data?.numPeople || 0;
  const session = useSession();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["savedPros"],
    queryFn: () => post({ route: "pros", values: {} }),
  });

  const existingIds = data ? data.map((x) => x.id) : [];

  const mutation = useMutation({
    mutationKey: ["person"],
    mutationFn: (id: number) => {
      return post({ route: "/query_list/add", values: { id } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedPros"] });
    },
  });

  const addQueryList = (id: number) => {
    if (session.account) {
      mutation.mutate(id);
    } else {
      setOpenModal(true); // Not logged in should just open modal
    }
  };

  return (
    <div className="mt-4">
      <div>
        {numPeople} result
        {numPeople !== 1 && "s"}
      </div>
      {results.length > 0 && (
        <div className="flex flex-row">
          <div className="flex flex-row justify-center items-center text-[#545454] ">
            <div className="w-[200px] bg-[#D4D4F6] py-2 h-full"></div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Manuscript Wishes</span>
            </div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Agency</span>
            </div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Links</span>
            </div>
          </div>
          <div className="flex w-[200px] justify-center items-center text-[#545454] py-2">
            <span>Add to query list</span>
          </div>
        </div>
      )}
      {results.map((person) => (
        <div key={person.id} className="flex flex-row">
          <div className="rounded-lg flex flex-row min-h-[70px]">
            <div className="flex w-[200px] bg-white justify-start items-center">
              <div className="ml-4 rounded-full min-h-[45px] min-w-[45px] bg-[#D4D4F6] flex justify-center items-center">
                <BookOpen></BookOpen>
              </div>
              <span className="ml-2">{person.name}</span>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center">
              <div className="bg-[#D0D7EE] px-2 py-1 rounded-md flex flex-row justify-center items-center">
                <a className="font-bold text-sm" href={person.href} target="_blank">
                  <div className="flex items-center">
                    <span>VIEW</span>
                    <ExternalLink className="ml-2" size={12} />
                  </div>
                </a>
              </div>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center">
              <span className="mt-2 text-center">{person.agency}</span>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center gap-2">
              {Object.keys(person.social_links || {}).length > 0 && (
                <>
                  <SocialLink name="Twitter" url={person.social_links?.twitter} />
                  <SocialLink name="PBM" url={person.social_links?.pbm} />
                  <SocialLink name="Website" url={person.social_links?.website} />
                  <SocialLink name="Email" url={person.social_links?.email} />
                </>
              )}
            </div>
          </div>
          <div className="flex w-[200px] items-center justify-center min-h-[70px]">
            {!existingIds.includes(person.id) ? (
              <div
                className="flex justify-center items-center rounded-md bg-[#3EA11C] w-[32px] h-[32px] cursor-pointer"
                onClick={() => {
                  addQueryList(person.id);
                }}
              >
                <Plus color="white" />
              </div>
            ) : (
              <div className="flex justify-center items-center rounded-md bg-[#9CA3AF] w-[32px] h-[32px]">
                <Check color="white" />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});
