import React, { CSSProperties } from "react";
import { cn } from "../lib/utils";
import { ClassValue } from "clsx";
import { Link } from "react-router-dom";

export const Logo = ({ styles }: { styles?: ClassValue }) => {
  return (
    <Link to="/">
      <div
        className={cn(
          "flex justify-center items-center border-4 border-slate-950 px-4 w-[160px] h-[42px] cursor-pointer",
          styles
        )}
      >
        <span className="font-bold text-lg">The Backcover</span>
      </div>
    </Link>
  );
};

export const SmallLogo = ({ styles }: { styles?: ClassValue }) => {
  return (
    <div className={cn(styles)}>
      <svg
        width="14"
        height="19"
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.67209 13.5714H13.3455C13.4701 13.5714 13.5713 13.7261 13.5713 13.9282C13.5713 14.1304 13.4701 14.2941 13.3455 14.2941L7.30729 14.2968C7.02211 14.2968 6.76279 14.4626 6.55476 14.7289H12.688C12.7505 14.7289 12.8009 14.8109 12.8009 14.9118C12.8009 15.0128 12.7503 15.0947 12.688 15.0947H6.3333C6.25615 15.261 6.19685 15.4462 6.15513 15.6467H12.1987C12.2611 15.6467 12.3115 15.7287 12.3115 15.8297C12.3115 15.9309 12.2611 16.0126 12.1987 16.0126H6.10255C6.09498 16.0992 6.08598 16.1851 6.08598 16.2752C6.08598 16.3743 6.09527 16.4694 6.10398 16.5648H12.237C12.2993 16.5648 12.3498 16.6468 12.3498 16.7475C12.3498 16.8482 12.2993 16.9302 12.237 16.9302H6.16042C6.20457 17.1319 6.26743 17.3169 6.34716 17.4824H12.688C12.7505 17.4824 12.8009 17.5644 12.8009 17.6651C12.8009 17.7665 12.7503 17.8483 12.688 17.8483H6.57519C6.77979 18.0988 7.03154 18.253 7.30729 18.253H13.3455C13.4701 18.253 13.5713 18.4167 13.5713 18.6189C13.5713 18.6216 13.5704 18.6237 13.5704 18.6265C13.5704 18.6293 13.5713 18.6311 13.5713 18.6337C13.5713 18.8358 13.4701 19 13.3455 19H1.67209C0.750106 19 -0.00014019 17.7844 -0.00014019 16.29C-0.00014019 14.7961 0.750106 13.5714 1.67209 13.5714Z"
          fill="black"
        />
        <path
          d="M0.0566406 16.6589L0.0566406 0.316043C0.0566406 0.141619 0.22678 0 0.449133 0C0.671485 0 0.851557 0.141619 0.851557 0.316043L0.854614 15.3902C0.854614 15.7894 1.03698 16.1525 1.32988 16.4437L1.32988 1.23657C1.32988 1.14916 1.42005 1.07855 1.5311 1.07855C1.64214 1.07855 1.73231 1.14936 1.73231 1.23657L1.73231 16.7538C1.91518 16.8618 2.11894 16.9448 2.33951 17.0032L2.33951 1.92166C2.33951 1.83425 2.42967 1.76364 2.54072 1.76364C2.65203 1.76364 2.74194 1.83425 2.74194 1.92166L2.74194 17.0768C2.83719 17.0874 2.93169 17.1 3.03077 17.1C3.13978 17.1 3.24446 17.087 3.34939 17.0748L3.34939 1.86806C3.34939 1.78084 3.43956 1.71003 3.55035 1.71003C3.66115 1.71003 3.75131 1.78084 3.75131 1.86806L3.75131 16.9958C3.97315 16.934 4.17666 16.846 4.35877 16.7343L4.35877 1.23657C4.35877 1.14916 4.44893 1.07855 4.55973 1.07855C4.67129 1.07855 4.76119 1.14936 4.76119 1.23657L4.76119 16.4151C5.03678 16.1287 5.20641 15.7762 5.20641 15.3902L5.20641 0.316043C5.20641 0.141619 5.38648 0 5.60883 0C5.61189 0 5.61418 0.00120016 5.61724 0.00120016C5.6203 0.00120016 5.62233 0 5.62513 0C5.84749 0 6.02807 0.141619 6.02807 0.316043L6.02807 16.6589C6.02807 17.9497 4.6909 19 3.04707 19C1.40375 19 0.0566406 17.9497 0.0566406 16.6589Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
