import React, { useEffect, useState } from "react";
import { SearchStore, Person } from "../lib/search";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../lib/ui/dialog";
import { Facebook, Mail, Twitter, Info, Globe, LucideIcon, Linkedin, Store } from "lucide-react";
import { useRootStoreContext } from "../providers/StoreProvider";
interface SearchResultsProps {
  results: Array<Person>;
}

interface SocialLinkProps {
  name: string;
  url: string | undefined;
}

function addHttp(url: string) {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url;
}

const SocialIcon = ({ Icon, url }: { Icon: LucideIcon; url: string }) => {
  return (
    <a className="text-xs" href={url} target="_blank">
      <Icon size={16} className="cursor-pointer" />
    </a>
  );
};

export const SocialLink = ({ name, url }: SocialLinkProps) => {
  const [copied, setCopied] = useState(false);
  if (!url) {
    return null;
  }
  const httpUrl = addHttp(url);
  switch (name) {
    case "Email":
      return (
        <div className="text-xs flex flex-row">
          <Mail
            size={16}
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setCopied(true);
            }}
          />
          {copied && <span className="ml-2">Copied!</span>}
        </div>
      );
    case "Facebook":
      return <SocialIcon Icon={Facebook} url={httpUrl} />;
    case "Linkedin":
      return <SocialIcon Icon={Linkedin} url={httpUrl} />;
    case "Twitter":
      return <SocialIcon Icon={Twitter} url={httpUrl} />;
    case "Store":
      return <SocialIcon Icon={Store} url={httpUrl} />;
    case "Website":
      return <SocialIcon Icon={Globe} url={httpUrl} />;
    default:
      return null;
  }
};

const SearchResults = observer(({ results }: SearchResultsProps) => {
  return (
    <div className="mt-4">
      {results.map((person, index) => (
        <div key={index} className="hover:bg-slate-100 rounded-lg py-2 px-2">
          <h3 className="text-lg font-bold">{person.name}</h3>
          <p className="mb-2">Genres: {person.genres}</p>
          <p className="mt-2">Agency: {person.agency}</p>
          <div className="flex flex-row mt-2 items-center">
            <a className="text-xs text-cyan-600" href={person.href}>
              Manuscript Wish List
            </a>
            {Object.keys(person.social_links || {}).length > 0 && (
              <>
                <span className="ml-2 text-cyan-600 opacity-80"> &#183;</span>
                <SocialLink name="Facebook" url={person.social_links?.facebook} />
                <SocialLink name="Twitter" url={person.social_links?.twitter} />
                <SocialLink name="Linkedin" url={person.social_links?.linkedin} />
                <SocialLink name="PBM" url={person.social_links?.pbm} />
                <SocialLink name="Website" url={person.social_links?.website} />
                <SocialLink name="Email" url={person.social_links?.email} />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
});

interface SearchPageProps {
  store: SearchStore;
}

const SAMPLE_QUERIES = [
  "michelle",
  "@genres:humor|romance @agency:penguin",
  "@genres:fantasy|action",
  "@agency:harpercollins|simon",
  "@genres:Middle Grade @genres:Children",
  "@genres:historical|children",
  "simon",
  "historical",
  "@genres:mystery|thriller|true crime",
  "thriller",
  "cookbook",
];

const InfoRow = () => {
  return (
    <div className="flex flex-row mt-2 h-4 items-center">
      <Dialog>
        <DialogTrigger>
          <Info size={16} strokeWidth={1.5} className="cursor-pointer" />
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader>
            <DialogTitle>About Us</DialogTitle>
            <DialogDescription>
              We help authors easily find agents and editors. <br /> <br />
              If you have any feedback, requests, please contact us at{" "}
              <a className="text-cyan-600" href="mailto:team@studiolanes.com">
                team@studiolanes.com
              </a>
              .
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <Twitter
        size={16}
        strokeWidth={1.5}
        className="ml-2 cursor-pointer"
        onClick={() => {
          window.location.href = "https://www.twitter.com/studiolanes";
        }}
      />
      <Mail
        size={16}
        strokeWidth={1.5}
        className="ml-2 cursor-pointer"
        onClick={() => {
          // @ts-ignore
          window.location = "mailto:team@studiolanes.com";
        }}
      />
    </div>
  );
};

export const SearchPage = observer(() => {
  const store = useRootStoreContext();
  useEffect(() => {
    if (store.query != "") {
      store.search(store.query);
    }
  }, []);

  return (
    <main className="px-4 max-w-screen-md mx-auto">
      <div className="flex flex-col h-full">
        <div className="sticky top-0 pt-4 bg-white pb-4 z-10">
          <div className="flex flex-row justify-between">
            <div>
              <div className="flex flex-row justify-between">
                <div>
                  <h1 className="text-2xl font-bold">Literary Agent Search</h1>
                </div>
              </div>

              <span className="text-xs">
                Search by name,{" "}
                <span
                  className="text-cyan-600 cursor-pointer"
                  onClick={action(() => {
                    store.query = "@genres:humor";
                    store.search(store.query);
                  })}
                >
                  @genres:humor
                </span>
                ,{" and "}
                <span
                  className="text-cyan-600 cursor-pointer"
                  onClick={action(() => {
                    store.query = "@agency:penguin";
                    store.search(store.query);
                  })}
                >
                  @agency:penguin
                </span>
              </span>
              <br />
            </div>
            <InfoRow />
          </div>
          <form
            className="mt-4"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <input
              autoFocus
              type="text"
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              placeholder="Search from 700+ agents..."
              value={store.query}
              onChange={action((e) => {
                store.query = e.target.value;
                store.search(e.target.value);
              })}
            />
          </form>
          <div className="mt-2 w-full flex flex-row justify-between">
            <div
              className="text-cyan-600 text-xs cursor-pointer"
              onClick={action(() => {
                store.query = SAMPLE_QUERIES[Math.floor(Math.random() * SAMPLE_QUERIES.length)];
                store.search(store.query);
              })}
            >
              I'm feeling lucky!
            </div>
            <div className="flex flex-col items-end">
              <div className="text-xs text-slate-400">
                {store.data?.numPeople || 0} result
                {(store.data?.numPeople || 0) !== 1 && "s"}
              </div>
              {store.data?.timing && store.query != "" && (
                <div className="text-xs text-slate-400">{store.data?.timing.toFixed(2)} ms</div>
              )}
            </div>
          </div>
        </div>
        <SearchResults results={store.data?.people || []} />
      </div>
    </main>
  );
});
