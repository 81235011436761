import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useRootStoreContext } from "../providers/StoreProvider";
import { cn } from "../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../lib/ui/popover";
import { Checkbox } from "../lib/ui/checkbox";
import { GENRES } from "../lib/helpers";
import { Search } from "lucide-react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "./Logo";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";
import { NavBar } from "./NavBar";
import { useSession } from "../providers/SessionProvider";
import { Button } from "../lib/ui/button";
import { AccountModal } from "./AccountModal";

type SearchType = "filters" | "agent" | "agency";
type FilterBar = "markets" | "categories" | "genres";

interface CheckboxContentProps {
  text: string;
  allContent: string[];
  setAllContent: (markets: string[]) => void;
}

const CheckboxContent = ({ text, allContent, setAllContent }: CheckboxContentProps) => {
  const checked = allContent.includes(text);
  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => {
        if (checked) {
          setAllContent(allContent.filter((market: string) => market != text));
        } else {
          setAllContent([...allContent, text]);
        }
      }}
    >
      <Checkbox checked={checked} />
      <span className="ml-2 mt-1">{text}</span>
    </div>
  );
};

const MARKETS = ["Picture Book", "Middle Grade", "Young Adult", "Adult", "New Adult"];
const CATEGORIES = ["Fiction", "Nonfiction"];

interface FilterButtonProps {
  className: boolean | string;
  onClick: () => void;
  allContent: string[];
  title: string;
  text: string;
}

const FilterButton = ({ className, onClick, allContent, text, title }: FilterButtonProps) => {
  return (
    <div
      className={cn("px-6 pt-2 bg-[#F4F4F4] rounded-full cursor-pointer max-w-[300px]", className)}
      onClick={onClick}
    >
      <div className="flex">
        <span>
          {title} {allContent.length > 0 && `(${allContent.length})`}
        </span>
      </div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <span className={cn("line-clamp-2", allContent.length === 0 && "opacity-40")}>
              {allContent.length === 0 ? text : allContent.join(", ")}
            </span>
          </TooltipTrigger>
          {allContent.length > 6 && (
            <TooltipContent>
              <div className="bg-white border px-4">{allContent.join(", ")}</div>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

interface FiltersProps {
  filterBar: string;
  markets: string[];
  categories: string[];
  genres: string[];
  setFilterBar: (thing: FilterBar) => void;
  setMarkets: (thingies: string[]) => void;
  setCategories: (thingies: string[]) => void;
  setGenres: (thingies: string[]) => void;
}

interface FilterContentProps {
  text: string;
  open: boolean;
  children: React.ReactNode;
  onClick: () => void;
  content: string[];
}

const FilterContent = ({ text, open, children, onClick, content }: FilterContentProps) => {
  return (
    <div
      className="flex flex-col bg-white rounded-xl justify-between drop-shadow-lg cursor-pointer p-4"
      onClick={onClick}
    >
      <div className="flex justify-between">
        <span className="font-bold">{text}</span>
        {!open && (
          <span className="text-sm ml-2">{(content && content.join(", ")) || `None selected`}</span>
        )}
      </div>
      {open && (
        <div className="flex flex-col max-h-[180px] overflow-y-auto mt-2">
          <div className="flex flex-col">{children}</div>
        </div>
      )}
    </div>
  );
};

const SmallScreenFilters = ({
  filterBar,
  markets,
  setFilterBar,
  setMarkets,
  categories,
  setCategories,
  genres,
  setGenres,
}: FiltersProps) => {
  const store = useRootStoreContext();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col w-full rounded-md mt-4  gap-4">
        <FilterContent
          text="Market"
          open={filterBar === "markets"}
          onClick={() => setFilterBar("markets")}
          content={markets}
        >
          {MARKETS.map((text) => (
            <CheckboxContent
              key={text}
              text={text}
              allContent={markets}
              setAllContent={setMarkets}
            />
          ))}
        </FilterContent>
        <FilterContent
          text="Category"
          open={filterBar === "categories"}
          onClick={() => setFilterBar("categories")}
          content={categories}
        >
          {CATEGORIES.map((text) => (
            <CheckboxContent
              key={text}
              text={text}
              allContent={categories}
              setAllContent={setCategories}
            />
          ))}
        </FilterContent>
        <FilterContent
          text="Genre"
          open={filterBar === "genres"}
          onClick={() => setFilterBar("genres")}
          content={genres}
        >
          {GENRES.map((text) => (
            <CheckboxContent key={text} text={text} allContent={genres} setAllContent={setGenres} />
          ))}
        </FilterContent>
      </div>
      <div
        className="flex justify-center items-center bg-black rounded-full m-2 p-4 cursor-pointer"
        onClick={() => {
          store.query = `genres:${[...markets, ...categories, ...genres].join(" OR ")}`;
          navigate("/main-search", {
            state: { searchType: "filters", markets, categories, genres },
          });
        }}
      >
        <Search color="white" />
        <span className="ml-2 text-white">Search</span>
      </div>
    </>
  );
};

const LargeScreenFilters = ({
  filterBar,
  markets,
  setFilterBar,
  setMarkets,
  categories,
  setCategories,
  genres,
  setGenres,
}: FiltersProps) => {
  const store = useRootStoreContext();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col md:flex-row bg-[#F4F4F4] rounded-full mt-4">
      <Popover open={filterBar === "markets"}>
        <PopoverTrigger>
          <FilterButton
            className={filterBar === "markets" && "bg-white"}
            onClick={() => setFilterBar("markets")}
            allContent={markets}
            title="Markets"
            text={"Filter your market audience"}
          ></FilterButton>
        </PopoverTrigger>
        <PopoverContent className="bg-white">
          {MARKETS.map((text) => (
            <CheckboxContent
              key={text}
              text={text}
              allContent={markets}
              setAllContent={setMarkets}
            />
          ))}
        </PopoverContent>
      </Popover>
      <Popover open={filterBar === "categories"}>
        <PopoverTrigger>
          <FilterButton
            className={filterBar === "categories" && "bg-white rounded-full"}
            onClick={() => setFilterBar("categories")}
            allContent={categories}
            title="Categories"
            text={"Filter your writing category"}
          ></FilterButton>
        </PopoverTrigger>
        <PopoverContent className="bg-white">
          {CATEGORIES.map((text) => (
            <CheckboxContent
              key={text}
              text={text}
              allContent={categories}
              setAllContent={setCategories}
            />
          ))}
        </PopoverContent>
      </Popover>
      <Popover open={filterBar === "genres"}>
        <PopoverTrigger>
          <FilterButton
            className={filterBar === "genres" && "bg-white"}
            onClick={() => setFilterBar("genres")}
            allContent={genres}
            title="Genres"
            text={"Search for genre filters"}
          ></FilterButton>
        </PopoverTrigger>
        <PopoverContent className="bg-white overflow-y-scroll">
          <div className="h-[300px]">
            {GENRES.map((text) => {
              return (
                <CheckboxContent
                  key={text}
                  text={text}
                  allContent={genres}
                  setAllContent={setGenres}
                />
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
      <div
        className="flex justify-center items-center bg-[#9EBEF0] rounded-full m-2 p-4 cursor-pointer"
        onClick={() => {
          store.query = `genres:${[...markets, ...categories, ...genres].join(" OR ")}`;
          navigate("/main-search", {
            state: { searchType: "filters", markets, categories, genres },
          });
        }}
      >
        <Search />
        <span className="ml-2">Search</span>
      </div>
    </div>
  );
};

const FilterSearchBar = () => {
  const location = useLocation();
  const [markets, setMarkets] = useState<string[]>(() => location?.state?.markets || []);
  const [categories, setCategories] = useState<string[]>(() => location?.state?.categories || []);
  const [genres, setGenres] = useState<string[]>(() => location?.state?.genres || []);
  const [filterBar, setFilterBar] = useState<FilterBar>(() => {
    if (genres.length > 0) {
      return "genres";
    } else if (categories.length > 0) {
      return "categories";
    } else {
      return "markets";
    }
  });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="flex flex-col justify-center items-center mt-8">
      {isSmallScreen ? (
        <SmallScreenFilters
          filterBar={filterBar}
          setFilterBar={setFilterBar}
          markets={markets}
          setMarkets={setMarkets}
          categories={categories}
          setCategories={setCategories}
          genres={genres}
          setGenres={setGenres}
        />
      ) : (
        <div className="flex justify-center items-center">
          <LargeScreenFilters
            filterBar={filterBar}
            setFilterBar={setFilterBar}
            markets={markets}
            setMarkets={setMarkets}
            categories={categories}
            setCategories={setCategories}
            genres={genres}
            setGenres={setGenres}
          />
        </div>
      )}
    </div>
  );
};

interface BigSearchBarProps {
  placeholder: string;
  queryPattern: (name: string) => string;
  title: string;
  type: string;
}
const BigSearchBar = ({ placeholder, queryPattern, title, type }: BigSearchBarProps) => {
  const store = useRootStoreContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [holder, setHolder] = useState(
    () => (type == location?.state?.searchType && location?.state?.holder) || ""
  );

  const search = () => {
    store.query = queryPattern(holder);
    navigate("/main-search", {
      state: { searchType: type, holder },
    });
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      search();
    }
  };

  return (
    <div className="flex items-center justify-center mt-36">
      <div className="flex flex-row rounded-full w-full md:w-[80%] bg-white py-4 px-4 items-center">
        <div className="flex flex-col flex-1 ml-2">
          <div>
            <span>{title}</span>
          </div>
          <div>
            <input
              value={holder}
              className="w-[100%] outline-none placeholder-gray-400"
              placeholder={placeholder}
              onKeyDown={handleKeyDown}
              onChange={(e) => setHolder(e.target.value)}
            ></input>
          </div>
        </div>
        <div
          className="flex justify-center items-center bg-[#C2D6F6] rounded-full p-4 cursor-pointer"
          onClick={search}
        >
          <Search />
        </div>
      </div>
    </div>
  );
};

const AgentSearchBar = () => {
  return (
    <BigSearchBar
      title="Agent Name"
      type={"agent"}
      placeholder="Search using the agent's name"
      queryPattern={(name: string) => {
        return `author:${name}`;
      }}
    ></BigSearchBar>
  );
};

const AgencySearchBar = () => {
  return (
    <BigSearchBar
      title="Agency"
      type={"agency"}
      placeholder={"Search using the agency name"}
      queryPattern={(name: string) => {
        return `agency:${name}`;
      }}
    ></BigSearchBar>
  );
};

const SearchBar = ({ searchType }: { searchType: SearchType }) => {
  if (searchType === "filters") {
    return <FilterSearchBar></FilterSearchBar>;
  } else if (searchType === "agent") {
    return <AgentSearchBar></AgentSearchBar>;
  } else if (searchType === "agency") {
    return <AgencySearchBar></AgencySearchBar>;
  } else {
    throw new Error("hello");
  }
};

interface SearchTypeButtonProps {
  text: string;
  className: string | boolean;
  onClick: () => void;
}

const SearchTypeButton = ({ text, className, onClick }: SearchTypeButtonProps) => {
  return (
    <div
      className={cn("px-2 md:px-6 py-3 bg-white rounded-full cursor-pointer", className)}
      onClick={onClick}
    >
      <span className="text-xs md:text-base">{text}</span>
    </div>
  );
};

export const PreSearchPage = observer(() => {
  const location = useLocation();
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [initialScreen, setInitialScreen] = useState("signIn");
  const [searchType, setSearchType] = useState<SearchType>(
    () => location?.state?.searchType || "filters"
  );
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const session = useSession();
  const accountName = session?.account?.name;
  const firstLetter = accountName ? accountName[0] : "";

  return (
    <div className="flex w-[100vw]">
      <NavBar page={"main"}></NavBar>
      <main
        className={cn(
          "relative px-2 mx-auto bg-custom-gradient w-[calc(100vw-210px)] h-[100vh] flex flex-col",
          isMobile && "w-[calc(100vw-56px)]"
        )}
      >
        <div className="flex justify-end items-center mt-4">
          {session.account ? (
            <div className="flex w-[150px] justify-end cursor-pointer items-center gap-1">
              <span className="hidden lg:block lg:whitespace-nowrap">Hi {accountName}</span>
              <div className="w-8 min-w-8 max-w-8 h-8 min-h-8 max-h-8 bg-[#A8B8EC] flex justify-center items-center rounded-full">
                <span>{firstLetter}</span>
              </div>
            </div>
          ) : (
            <div className="w-[150px] flex justify-end">
              <Button
                className="bg-[#A8B8EC] px-3 py-1 rounded-md"
                onClick={() => {
                  setOpenAccountModal(true);
                  setInitialScreen("signIn");
                }}
              >
                <span className="text-black">Sign In</span>
              </Button>
            </div>
          )}
        </div>
        <div className="text-4xl font-bold flex justify-center items-center mt-5">
          <span>Search for Agents</span>
        </div>
        <div className="flex justify-center items-center mt-8">
          <div className="flex flex-row bg-white rounded-full">
            <SearchTypeButton
              text={"Search by filters"}
              className={searchType == "filters" && "bg-[#9EBEF0]"}
              onClick={() => setSearchType("filters")}
            ></SearchTypeButton>
            <SearchTypeButton
              text={"Search by agent"}
              className={searchType == "agent" && "bg-[#9EBEF0] rounded-full"}
              onClick={() => setSearchType("agent")}
            ></SearchTypeButton>
            <SearchTypeButton
              text={"Search by agency"}
              className={searchType == "agency" && "bg-[#9EBEF0]"}
              onClick={() => setSearchType("agency")}
            ></SearchTypeButton>
          </div>
        </div>
        <SearchBar searchType={searchType}></SearchBar>
        <div className="flex flex-1"></div>
        <AccountModal
          initialScreen={initialScreen}
          openModal={openAccountModal}
          onOpenChange={() => setOpenAccountModal(!openAccountModal)}
        />
      </main>
    </div>
  );
});
