import { SearchStore } from "../lib/search";
import React, { useContext } from "react";

export const RootStoreContext = React.createContext<SearchStore | undefined>(undefined);

export const useRootStoreContext = () => {
  const rootStore = useContext(RootStoreContext);

  if (rootStore === undefined) {
    throw new Error("No store");
  }
  return rootStore;
};
