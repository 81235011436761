import React, { useContext, useEffect, useState } from "react";

interface LocalStorage {
  savedPros: number[];
  setSavedPros: (value: number[]) => void;
  setLocalStorage: (key: string, value: any) => void;
  getLocalStorage: (key: string, value?: any) => void;
}

export const LocalStorageContext = React.createContext<LocalStorage | undefined>(undefined);

function setLocalStorage(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

function getLocalStorage(key: string, initialValue?: any) {
  try {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
}

export const LocalStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const [savedPros, setSavedPros] = useState(() => getLocalStorage("savedPros", []));
  useEffect(() => {
    setLocalStorage("savedPros", savedPros);
  }, [savedPros]);
  return (
    <LocalStorageContext.Provider
      value={{ savedPros, setSavedPros, setLocalStorage, getLocalStorage }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorage = () => {
  const rootStore = useContext(LocalStorageContext);

  if (rootStore === undefined) {
    throw new Error("No store");
  }
  return rootStore;
};
