import { makeAutoObservable, runInAction } from "mobx";

interface SocialLinks {
  twitter?: string;
  facebook?: string;
  website?: string;
  email?: string;
  linkedin?: string;
  pbm?: string;
}

export interface Person {
  id: number;
  name: string;
  agency: string;
  genres: Array<string>;
  href: string;
  social_links: SocialLinks;
}

interface SearchResult {
  people: Array<Person>;
  timing: number;
  numPeople: number;
}

export type Searcher = {
  data: SearchResult | undefined;
  error: string | null;
  search: (query: string) => void;
};

export class SearchStore {
  query: string = "";
  data: SearchResult | undefined;
  error: string | null = null;
  abort: AbortController | null = null;
  lastQuery: string | null = null;
  debounceTimer: NodeJS.Timeout | null = null;
  debounceDelay: number = 30;

  constructor() {
    makeAutoObservable(this);
  }

  async search(query: string) {
    if (query === this.lastQuery) return;
    this.lastQuery = query;
    this.debounceTimer && clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.performSearch(query);
    }, this.debounceDelay);
  }

  async performSearch(query: string) {
    this.abort?.abort();
    this.abort = new AbortController();
    let localAbort = this.abort;
    try {
      const resp = await fetch("/search?q=" + encodeURIComponent(query), {
        signal: this.abort.signal,
      });
      if (!resp.ok) {
        const obj = await resp.json();
        runInAction(() => {
          this.error = `Error searching for ${query}: ${obj.error}`;
        });
      } else {
        const obj = await resp.json();
        runInAction(() => {
          this.data = obj;
          this.error = null;
        });
      }
    } catch (err: any) {
      if (!localAbort.signal.aborted) {
        // Network error or some other issue.
        runInAction(() => {
          this.error = err.message;
        });
      }
    }
  }
}
