import { useMutation } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { post } from "../lib/fetch";

interface Account {
  name: string;
  email: string;
}

type SessionContextType = {
  account: Account | null;
};
type ApiContextType = {
  updateAccount: (user: any) => void;
};

export const SessionContext = React.createContext<SessionContextType | undefined>(undefined);
export const ApiContext = React.createContext<ApiContextType | undefined>(undefined);
export type SessionState = {};

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState({
    account: null,
  });

  const checkMe = useMutation({
    mutationKey: ["me"],
    mutationFn: () => {
      return post({ route: "/auth/me", values: {} });
    },
    onSuccess: (user) => {
      if (user) {
        api.updateAccount(user);
      }
    },
  });

  useEffect(() => {
    checkMe.mutate();
  }, []);

  const updateAccount = React.useCallback(
    (user) => {
      setState({ ...state, account: user });
    },
    [state]
  );

  const api = React.useMemo(() => {
    return { updateAccount };
  }, [updateAccount]);

  return (
    <SessionContext.Provider value={state}>
      <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const rootStore = useContext(SessionContext);

  if (rootStore === undefined) {
    throw new Error("No store");
  }
  return rootStore;
};

export const useApi = () => {
  const rootStore = useContext(ApiContext);

  if (rootStore === undefined) {
    throw new Error("No store");
  }
  return rootStore;
};
