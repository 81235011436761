import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "../lib/ui/dialog";
import { Button } from "../lib/ui/button";
import { useMutation } from "@tanstack/react-query";
import { post } from "../lib/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../providers/SessionProvider";

export const ConfirmationPage = () => {
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const api = useApi();

  const closeScreen = () => {
    navigate("/pre-search");
  };

  const mutation = useMutation({
    mutationKey: ["confirmation"],
    mutationFn: () => post({ route: "/auth/confirmation", values: { token } }),
    onSuccess: (user) => {
      api.updateAccount(user);
      closeScreen();
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  return (
    <main className="relative px-4 mx-auto bg-custom-gradient w-[100vw] min-h-[100vh] flex flex-col">
      <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
        <DialogContent
          className="bg-white [&>*]:font-kufam px-16 py-12 rounded-3xl min-h-[500px]"
          onPointerDownOutside={(e) => e.preventDefault()}
          onCloseAutoFocus={() => {
            closeScreen();
          }}
        >
          {mutation.isPending ? (
            "Loading..."
          ) : mutation.isSuccess ? (
            <div className="flex flex-col items-center justify-center">
              <DialogTitle className="text-2xl">Account creation complete!</DialogTitle>
              <DialogDescription className="mt-4">Happy searching!</DialogDescription>
              <div className="mt-16"></div>
              <Button
                type="submit"
                size="lg"
                className="bg-[#818CF8] rounded-full h-[48px]"
                onClick={() => {
                  closeScreen();
                }}
              >
                Back to home
              </Button>
            </div>
          ) : (
            "Something went wrong..."
          )}
        </DialogContent>
      </Dialog>
    </main>
  );
};
