import React, { useState } from "react";
import { SearchStore } from "../lib/search";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import { POSTHOG_PUBLIC_KEY, POSTHOG_OPTIONS } from "../lib/posthog";
import { RootStoreContext } from "../providers/StoreProvider";
import { SearchPage } from "./SearchPage";
import { AboutPage } from "./AboutPage";
import { HomePage } from "./HomePage";
import { PreSearchPage } from "./PreSearchPage";
import { MainSearch } from "./MainSearch";
import { LocalStorageProvider } from "../providers/LocalStorageProvider";
import { MyList } from "./MyList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfirmationPage } from "./ConfirmationPage";
import { SessionProvider } from "../providers/SessionProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/hidden-search",
    element: <SearchPage />,
  },
  {
    path: "/pre-search",
    element: <PreSearchPage />,
  },
  {
    path: "/main-search",
    element: <MainSearch />,
  },
  {
    path: "/my-list",
    element: <MyList />,
  },
  {
    path: "/confirmation",
    element: <ConfirmationPage />,
  },
]);

const queryClient = new QueryClient();

export const App = () => {
  const [store, _] = useState(() => new SearchStore());
  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider apiKey={POSTHOG_PUBLIC_KEY} options={POSTHOG_OPTIONS}>
        <LocalStorageProvider>
          <SessionProvider>
            <RootStoreContext.Provider value={store}>
              <RouterProvider router={router} />
            </RootStoreContext.Provider>
          </SessionProvider>
        </LocalStorageProvider>
      </PostHogProvider>
    </QueryClientProvider>
  );
};
