import React from "react";
import { Book, LayoutList, LogOut, Search } from "lucide-react";
import { Logo, SmallLogo } from "./Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";
import { useApi, useSession } from "../providers/SessionProvider";
import { useMutation } from "@tanstack/react-query";
import { post } from "../lib/fetch";
import { useMediaQuery } from "react-responsive";

export const NavBar = ({ page }: { page: string }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const api = useApi();
  const logoutMutation = useMutation({
    mutationKey: ["mutation"],
    mutationFn: () => {
      return post({ route: "/auth/sign_out", values: {} });
    },
    onSuccess: () => {
      api.updateAccount(null);
      navigate("/");
    },
  });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <nav
      className={cn(
        "w-[210px] bg-white min-h-screen flex items-center flex-col",
        isMobile && "w-[56px]"
      )}
    >
      {isMobile ? (
        <Link to="/">
          <SmallLogo styles={"mt-4"} />
        </Link>
      ) : (
        <Logo styles={"mt-4"}></Logo>
      )}
      <div className="flex flex-col gap-4 mt-8">
        <div
          className={cn(
            "flex items-center gap-2 px-4 py-2 cursor-pointer",
            page === "main" && "bg-[#E0E7FF] rounded-full"
          )}
          onClick={() => {
            navigate("/pre-search", { state: location.state });
          }}
        >
          <Search size={20} />
          {!isMobile && <span>Search Agents</span>}
        </div>
        <div
          className={cn(
            "flex items-center gap-2 px-4 py-2 cursor-pointer",
            page === "list" && "bg-[#E0E7FF] rounded-full "
          )}
          onClick={() => {
            navigate("/my-list", { state: location.state });
          }}
        >
          <LayoutList size={20} />
          {!isMobile && <span>Your Query List</span>}
        </div>
        <Link to="https://scripteditor.thebackcover.com">
          <div
            className={cn(
              "flex items-center gap-2 px-4 py-2 cursor-pointer",
              page === "script" && "bg-[#E0E7FF] rounded-full "
            )}
          >
            <Book />
            {!isMobile && <span>Script Editor</span>}
          </div>
        </Link>
      </div>
      {session.account && (
        <>
          <div className="flex-1" />
          <div className="flex gap-2 cursor-pointer mb-8" onClick={() => logoutMutation.mutate()}>
            <LogOut size={20} />
            {!isMobile && <span>Log out</span>}
          </div>
        </>
      )}
    </nav>
  );
};
