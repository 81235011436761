const token = document.getElementsByName("csrf-token")[0] as HTMLMetaElement;

interface postProps {
  route: string;
  values: Record<string, any>;
}

export const post = ({ route, values }: postProps) => {
  return fetch(route, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token.content,
      credentials: "include",
    },
    body: JSON.stringify({ ...values }),
  }).then(async (res) => {
    if (res.ok) {
      return res.json();
    }
    const actualError = await res.json();
    throw new Error(actualError.error);
  });
};
