import React from "react";
import { Header } from "./HomePage";
import jenny from "../images/jenny.png";
import herrick from "../images/herrick.png";
import anabella from "../images/anabella.png";

export const AboutPage = () => {
  return (
    <main className="relative mx-auto bg-custom-gradient w-[100vw] h-[100vh] flex flex-col overflow-auto">
      <Header></Header>
      <div className="flex flex-1 flex-col items-center px-4">
        <h1 className="text-5xl font-bold font-kufam mt-8">About</h1>
        <div className="mt-16 flex flex-col items-center max-w-screen-lg">
          <span className="font-bold text-xl">
            The Backcover emerged from the frustration of juggling too many websites to research
            literary agents and updating documents as an unagented author/illustrator.
            <br></br> <br></br>As storytellers, we need to spend more time on what really matters -
            crafting our stories.
          </span>
          <div className="bg-[#A7DBF8] mt-2 p-2">
            <span className="font-bold text-xl">
              So, The Backcover was born to streamline the querying process with one central tool.
            </span>
          </div>
        </div>

        <div className="flex flex-col max-w-screen-lg mt-16">
          <span className="font-bold text-xl">
            Currently, the tool is designed to address key questions in the querying process, all in
            one place:
          </span>
          <ul className="ml-4 list-disc mt-2">
            <li>Which agents/editors are accepting my genre?</li>
            <li>What types of manuscripts are they seeking?</li>
          </ul>
        </div>

        <div className="flex flex-col mt-16 max-w-screen-lg">
          <span>
            We named this tool The Backcover to symbolize the acknowledgements section found at the
            back of a book. Creating a book takes a WHOLE team, and we want to honor that.
            <br></br>
            <br></br>
            Our aim is to grow and improve this tool to support you throughout your entire
            publishing adventure. We're currently developing more features to make creating and
            publishing your stories even more accessible.
            <br></br>
            <br></br>
            We hope this tool adds significant value to your journey, making your path to
            publication more achievable and streamlined :)
          </span>
          <div>
            <span className="font-bold">
              <br></br>
              <br></br>
              If we can assist you in any way, message us at team@studiolanes.com. We're here to
              help and excited to hear from you!
            </span>
          </div>
        </div>

        <div className="mt-16 max-w-screen-lg mb-20">
          <div className="flex justify-center">
            <span className="font-bold text-xl">
              Meet the mighty small team building out The Backcover:
            </span>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center overflow-x-auto mt-4">
            <div className="flex flex-row md:flex-col items-center">
              <img
                className="max-w-screen-md h-[160px] w-[160px] md:h-[300px] md:w-[300px]"
                src={jenny}
              ></img>
              <div className="flex flex-col w-[180px] h-[128px] justify-center md:justify-start">
                <span className="font-bold">Jenny Fang</span>
                <span>Author/Illustrator &</span>
                <span>Product Manager</span>
                <span className="mt-2">
                  Find her at <a href="https://jennyfang.com">jennyfang.com</a> and{" "}
                  <a href="https://www.instagram.com/fangjart/">@fangjart</a> on Instagram.
                </span>
              </div>
            </div>
            <div className="flex flex-row md:flex-col items-center">
              <img
                className="max-w-screen-md h-[160px] w-[160px] md:h-[300px] md:w-[300px]"
                src={anabella}
              ></img>
              <div className="flex flex-col w-[180px] h-[128px] justify-center md:justify-start">
                <span className="font-bold">Anabella McShane</span>
                <span>Designer</span>
              </div>
            </div>
            <div className="flex flex-row md:flex-col items-center">
              <img
                className="max-w-screen-md h-[160px] w-[160px] md:h-[300px] md:w-[300px]"
                src={herrick}
              ></img>
              <div className="flex flex-col w-[180px] h-[128px] justify-center md:justify-start">
                <span className="font-bold">Herrick Fang</span>
                <span>Builder</span>
                <a href="https://www.studiolanes.com/">
                  <span>
                    <u>studiolanes.com</u>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
