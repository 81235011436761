import { observer } from "mobx-react-lite";
import React from "react";
import { Person } from "../lib/search";
import { SocialLink } from "./SearchPage";
import { BookOpen, ExternalLink, Minus, MoveLeft, Plus } from "lucide-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSession } from "../providers/SessionProvider";
import { post } from "../lib/fetch";
import { NavBar } from "./NavBar";
import { useMediaQuery } from "react-responsive";
import { cn } from "../lib/utils";

export const MyList = observer(() => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { data, refetch } = useQuery({
    queryKey: ["savedPros"],
    queryFn: () => post({ route: "pros", values: {} }),
  });

  return (
    <div className="flex w-[100vw]">
      <NavBar page="list"></NavBar>
      <main
        className={cn(
          "relative px-4 mx-auto bg-custom-gradient w-[calc(100vw-210px)] min-h-[100vh] flex flex-col",
          isMobile && "w-[calc(100vw-56px)]"
        )}
      >
        <div className="flex flex-col h-full">
          <div className="py-2 z-10 rounded-full px-8 mt-4">
            <span className="text-xl">My List ({data?.length || 0})</span>
          </div>
          <div className="flex mx-auto overflow-x-auto">
            <div className="flex flex-col justify-center items-center flex-1">
              <MyListResults results={data || []} refetch={refetch}></MyListResults>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});

const MyListResults = ({ results, refetch }: { results: Array<Person>; refetch: () => void }) => {
  const session = useSession();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["person"],
    mutationFn: (id) => {
      return post({ route: "/query_list/remove", values: { id } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["savedPros"] });
    },
  });

  const removeQueryList = (id: number) => {
    if (session.account) {
      // Do something else
      mutation.mutate(id);
      queryClient.invalidateQueries({ queryKey: ["savedPros"] });
    }
  };

  return (
    <div className="mt-4">
      {results.length > 0 && (
        <div className="flex flex-row">
          <div className="flex flex-row justify-center items-center text-[#545454] ">
            <div className="w-[200px] bg-[#D4D4F6] py-2 h-full"></div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Manuscript Wishes</span>
            </div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Agency</span>
            </div>
            <div className="flex w-[200px] justify-center items-center text-[#545454] bg-[#D4D4F6] py-2">
              <span>Links</span>
            </div>
          </div>
          <div className="flex w-[150px] justify-center items-center text-[#545454] py-2"></div>
        </div>
      )}
      {results.map((person) => (
        <div key={person.id} className="flex flex-row">
          <div className="rounded-lg flex flex-row min-h-[70px]">
            <div className="flex w-[200px] bg-white justify-start items-center">
              <div className="ml-4 rounded-full min-h-[45px] min-w-[45px] bg-[#D4D4F6] flex justify-center items-center">
                <BookOpen></BookOpen>
              </div>
              <span className="ml-2">{person.name}</span>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center">
              <div className="bg-[#D0D7EE] px-2 py-1 rounded-md flex flex-row justify-center items-center">
                <a className="font-bold text-sm" href={person.href} target="_blank">
                  <div className="flex items-center">
                    <span>VIEW</span>
                    <ExternalLink className="ml-2" size={12} />
                  </div>
                </a>
              </div>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center">
              <span className="mt-2 text-center">{person.agency}</span>
            </div>
            <div className="flex w-[200px] bg-white justify-center items-center gap-2">
              {Object.keys(person.social_links || {}).length > 0 && (
                <>
                  <SocialLink name="Twitter" url={person.social_links?.twitter} />
                  <SocialLink name="PBM" url={person.social_links?.pbm} />
                  <SocialLink name="Website" url={person.social_links?.website} />
                  <SocialLink name="Email" url={person.social_links?.email} />
                </>
              )}
            </div>
          </div>
          <div className="flex w-[150px] items-center justify-center min-h-[70px]">
            <div
              className="flex justify-center items-center rounded-md bg-[#9a1e1e] w-[32px] h-[32px] cursor-pointer"
              onClick={() => {
                removeQueryList(person.id);
              }}
            >
              <Minus color="white" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
