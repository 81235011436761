import React from "react";
import { Search, BookOpen, LayoutList, Sparkle } from "lucide-react";
import book from "../images/book.png";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";

export const Header = () => {
  return (
    <div className="flex flex-row items-center justify-between py-8 px-4">
      <Logo />
      <div className="flex gap-2 items-center">
        <Link to="/pre-search">
          <span className="text-sm md:text-xl whitespace-nowrap">Agent Search</span>
        </Link>
        <Link to="/about">
          <span className="text-sm md:text-xl whitespace-nowrap">About</span>
        </Link>
      </div>
    </div>
  );
};

const Title = () => {
  return (
    <div className="flex justify-center items-center px-8">
      <span className="font-kufam font-medium text-2xl md:text-5xl max-w-screen-md">
        Looking for a literary agent to champion your book?
      </span>
    </div>
  );
};

const TryButton = () => {
  return (
    <div className="flex justify-center items-center mt-8 z-10">
      <div className="flex flex-row">
        <div className="relative flex flex-col justify-end mr-4">
          <Sparkle
            className="absolute bottom-[-16px] right-0"
            fill="white"
            size={40}
            color="white"
          ></Sparkle>
        </div>
        <div className="bg-black rounded-2xl px-10 py-8">
          <Link to="/pre-search">
            <span className="font-kufam text-white text-xl font-bold">
              Search Agents Now &#8594;
            </span>
          </Link>
        </div>
        <div className="relative flex flex-col justify-start ml-4">
          <Sparkle className="absolute top-[-16px]" fill="white" size={40} color="white"></Sparkle>
        </div>
      </div>
    </div>
  );
};

const ContentPill = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center rounded-full bg-white px-8 py-4 w-[320px] max-w-[320px] h-[80px]">
      {children}
    </div>
  );
};

const ContentPillText = ({ children }: { children: React.ReactNode }) => {
  return <span className="font-hind text-sm md:text-base">{children}</span>;
};

const ContentPillIcon = ({ children }: { children: React.ReactNode }) => {
  return <div className="mr-4">{children}</div>;
};

const Content = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-5 justify-center items-center z-10 my-16">
      <ContentPill>
        <ContentPillIcon>
          <Search size={36}></Search>
        </ContentPillIcon>
        <ContentPillText>Find literary agents.</ContentPillText>
      </ContentPill>
      <ContentPill>
        <ContentPillIcon>
          <BookOpen size={36}></BookOpen>
        </ContentPillIcon>
        <ContentPillText>Manage queries.</ContentPillText>
      </ContentPill>
      <ContentPill>
        <ContentPillIcon>
          <LayoutList size={36}></LayoutList>
        </ContentPillIcon>
        <ContentPillText>
          Use the script editor to write and format your picture books and graphic novels.
        </ContentPillText>
      </ContentPill>
    </div>
  );
};

export const HomePage = () => {
  return (
    <main className="relative mx-auto bg-custom-gradient w-[100vw] h-[100vh] flex flex-col overflow-auto">
      <Header></Header>
      <div className="flex flex-1"></div>
      <Title></Title>
      <TryButton></TryButton>
      <div className="flex flex-1"></div>
      <Content></Content>
      <div className="absolute bottom-0 w-[100vw] z-0">
        <div className="flex justify-center">
          <img className="max-w-screen-md" src={book}></img>
        </div>
      </div>
    </main>
  );
};
