export const GENRES = [
  "Action/Adventure",
  "Art",
  "BIPOC Literature",
  "Biography",
  "Business",
  "Children",
  "Cookbooks",
  "Crafts/DIY",
  "Crime",
  "Erotica",
  "Family Saga",
  "Fantasy",
  "Fashion",
  "Feminism and women\\'s issues",
  "Fitness",
  "Folklore",
  "Gothic",
  "Graphic Novel",
  "Health",
  "Historical",
  "History",
  "Horror",
  "Humor",
  "Journalism",
  "LGBTQ",
  "Magical Realism",
  "Memoir",
  "Mystery",
  "Parenting",
  "Poetry",
  "Psychology",
  "Religious",
  "Romance",
  "Science",
  "Science Fiction",
  "Self-help",
  "Short Story",
  "Sports",
  "Thriller",
  "Travel",
];
